import { Component, OnInit, ViewChild } from '@angular/core';
import { InviteService } from '../invite/invite.service';
import { HomePageService } from '../home-page/home-page.service';
import * as _ from 'lodash';
import { MatOption } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


interface Group {
  group_code: string;
  _id: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-download-baselines',
  templateUrl: './download-baselines.component.html',
  styleUrls: ['./download-baselines.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DownloadBaselinesComponent implements OnInit {

  groups: Group[] = [];
  downloadForm: FormGroup;
  maxDate: Date;
  minEndDate: Date;

  @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
  constructor(
    private fb: FormBuilder,
    private inviteService: InviteService,
    private HomePageService: HomePageService
  ) {
    this.setForm();
  }

  ngOnInit() {
    this.inviteService.getAllGroups().subscribe((res: any) => {
      const groupsDataSet = res.result;
      this.groups = _.map(groupsDataSet, g => _.pick(g, 'group_code', '_id'));
    }, (err) => {
      console.log(err);
    });
  }

  downloadBaselines() {
    const groupIds = this.downloadForm.get('groupSelect').value;
    this.HomePageService.downloadBaselines(groupIds).subscribe((result: any) => {
      const a = document.createElement('a');
      const disposition = result.headers.get('Content-Disposition');
      // extracting filename from response headers.
      if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) { 
            a.download = matches[1].replace(/['"]/g, '');
          }
      }
      a.href = window.URL.createObjectURL(result.body);
      document.body.appendChild(a);
      a.click();
      // if (result.result.length > 0) {
      //   _.forEach(result.result, (value) => {
      //     _.forEach(value, (val, key1) => {
      //       const type = typeof val;
      //       if (val && type === 'object') {
      //         _.forEach(val, (val2, key2) => {
      //           value[key1 + '.' + key2] = val2;
      //         });
      //       }
      //     });
      //   });

      //   // _.forEach(result.result, function (value,key) {
      //   //     // value['username'] = (value.user && value.user['full_name']) ? value.user['full_name'] : "-";
      //   //     // console.log(value.user['full_name']);
      //   // });
      // }
      // // console.log(result.result);
      // this.HomePageService.exportAsExcelFile(result.result, 'sample');
    }, (error) => {
      console.log(error);
      // Alert Service message comes here
    });

  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.downloadForm.controls.groupSelect
        .patchValue([...this.groups.map(item => item._id), 0]);
    } else {
      this.downloadForm.controls.groupSelect.patchValue([]);
    }
  }

  private setForm() {
    this.downloadForm = this.fb.group({
      groupSelect: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required]
    });
  }
}
