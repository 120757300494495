import { Component, OnInit, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { InviteService } from './invite.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomConditionalValidator } from './../angular-reactive-forms-customvalidation';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable';


export interface Groups {
  _id: string;
  group_code: string;
}

export interface Invites {
  name: string;
}
@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  @ViewChild('chipList', {static: false}) chipList;

  selectable = true;
  removable = true;
  addOnBlur = true;
  inviteForm: FormGroup;
  // emails: FormGroup;
  errorMessage: string;
  emailError: string;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  inviteEmails: Invites[] = [

  ];
  selectedItem: string;
  groups: Groups[] = [];
  filteredGroupOptions: Observable<Groups[]>;
  dataArray = this.groups;
  showCreateGroup = false;
  invitationListRows = [];
  userRole = "";

  invitationListColumns = [
    { prop: 'email', name: 'Facilitator Email' }, 
    { name: 'Status', prop: 'status' }, 
    { name: 'Group', prop:'group_code'},
    { name: 'Invited By', prop:'invitee'},
    { name: 'Date invited', prop:'date_invited'}];
  ColumnMode = ColumnMode;
  SortType = SortType;
  constructor(
    private fb: FormBuilder,
    private cookieService: CookieService,
    private inviteService: InviteService,
    private router: Router,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.setInviteForm();
    this.userRole = this.cookieService.get('role');
    this.setFilterOptions();
    this.inviteService.getAllGroups().subscribe((res: any) =>{
      const groupsDataSet = res.result;
      this.groups = _.map(groupsDataSet, g => _.pick(g, 'group_code', '_id'));
      this.dataArray = this.groups;
    }, (err) => {
      console.log(err);
    });
    
    this.getAllInvites();
  }

  inviteUsers() {
    if (!this.inviteForm.valid) {
     // this.errorMessage = 'Fields are required';
      if (this.inviteEmails.length === 0) {
        this.chipList.errorState = true;
      }
    } else {
      this.chipList.errorState = false;
      this.errorMessage = '';
      // making api call
      const groupId = this.inviteForm.get('group').value;
      const data = {
        invites: _.map(this.inviteEmails, 'name'),
        createGroup: false,
        group: null,
        role: this.inviteForm.get('selectedRole').value
      };

      if (groupId === '$$NEW$$') {
        data.createGroup = true;
        data.group = this.inviteForm.get('createGroupName').value;
      } else {
        // tslint:disable-next-line:no-string-literal
        data.group = this.inviteForm.get('group').value['_id'];
      }

      this.inviteService.inviteUsers(data).subscribe((result: any) => {

        this.clearInviteForm();

        this.snackBar.open('Invites Sent Successfully', 'OK', {
          duration: 5000,
          verticalPosition: 'top'
        });
        this.getAllInvites();
      }, (err) => {
        if (err.error && err.error.code === 11000) {
          this.errorMessage = 'Invite already sent';
        } else {
          this.errorMessage = err.error.message;
        }
      })
    }
  }

  getAllInvites() {
    this.inviteService.getAllInvites().subscribe((res: any) =>{
      let invites = res.invites || [];
      this.invitationListRows = invites.map(row => ({
        ...row,
        group_code: row['group'] && row['group']['group_code'] ? row['group']['group_code'] : '',
        invitee: row['invited_by'] && row['invited_by']['full_name'] ? row['invited_by']['full_name'] : '',
        date_invited: moment(row.createdAt).format('ll')
      }));
      
    }, (err) => {
      console.log(err);
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // // Add our fruit
    // if ((value || '').trim() && this.validateEmail(input)) {
    if ((value || '').trim() && this.validateEmail(input)) {
      this.inviteEmails.push({ name: value.trim() });
      this.chipList.errorState = false;
      input.value = '';
    } else {
      this.chipList.errorState = true;
      input.value = '';
    }
  }

  validateEmail(emailField) {
    const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$/;
    console.log(emailField.value);
    if (reg.test(emailField.value) === false) {
      // alert('Invalid Email Address');
      return false;
    }

    return true;

  }

  remove(inviteEmails: Invites): void {
    const index = this.inviteEmails.indexOf(inviteEmails);

    if (index >= 0) {
      this.inviteEmails.splice(index, 1);
    }
  }

  gotoHome() {
    this.router.navigate(['/home']);
  }

  onKey(value) {
    this.dataArray = [];
    this.selectSearch(value);
  }

  clearInviteForm() {
    this.inviteForm.markAsPristine();
    this.inviteForm.markAsUntouched();
    this.resetForm(this.inviteForm);
    this.inviteEmails = [];
    this.showCreateGroup = false;
    this.errorMessage = '';
  }

  resetForm(form: FormGroup) {
    form.reset();

    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null) ;
    });
}

  selectSearch(value) {
    const filter = value.toLowerCase();
    this.dataArray = this.groups.filter( i => i.group_code.toLowerCase().indexOf(filter) >= 0 );
  }

  addNewGroup(event) {
    console.log(event, 'here');
    if (event.value === '$$NEW$$') {
      this.showCreateGroup = true;
    } else {
      this.showCreateGroup = false;
      // TODO: Debug why the actual updateValueAndValidity is failing here.
      this.inviteForm.get('createGroupName').clearValidators();
    }
    this.inviteForm.get('createGroupName').updateValueAndValidity();
  }

  private setInviteForm() {
    this.inviteForm = this.fb.group({
      emails: ['', Validators.required],
      group: ['', Validators.required],
     createGroupName: [
       '',
       CustomConditionalValidator.conditionalValidator(() => (this.inviteForm.get('group').value === '$$NEW$$'),
        Validators.required)
     ],
     selectedRole: ['USER', Validators.required]
    });
  }

  private setFilterOptions() {
    // tslint:disable-next-line:no-string-literal
    this.filteredGroupOptions = this.inviteForm.controls['group'].valueChanges.
      pipe(startWith(''),
        map(value => typeof value === 'string' ? value : (value as Groups).group_code),
        map(name => name ? this._filter(name) : this.groups.slice()));
  }

  private _filter(name: string): Groups[] {
    let filterValue = name.toString().toLowerCase();
    filterValue = filterValue.replace(/ +/g, '').trim();

    const role = this.groups.filter(option => option.group_code.toLowerCase().indexOf(filterValue) === 0);

    return role;
  }
}
