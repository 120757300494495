import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LoginService } from './login.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

// import { UtilsService } from '../services/utils.service';
import { storageConstants } from '../middleware/constants';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  username: FormGroup;
  password: FormGroup;
  errorMessage: String;
  versionNumber = storageConstants.VERSION_NUMBER;
  constructor(private fb: FormBuilder,
    private loginService: LoginService,
    private cookieService: CookieService,
    private router: Router,
  ) { }

  ngOnInit() {
    // declaring the login form 
    this.loginForm = this.fb.group({
      // username: ['', Validators.required,Validators.email],
      username: ['', Validators.required],
      
      password: ['', Validators.required]
    });
  }
  validateEmail(emailField) {
    var reg = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    //console.log(emailField);
    if (reg.test(emailField) == false) {
      // alert('Invalid Email Address');
      return false;
    }

    return true;

  }

  // login function starts
  login() {

    // checking the validations of form
    if (!this.loginForm.valid) {
      this.errorMessage = "Missing Fields"
    } else if(this.loginForm.value.username && !this.validateEmail(this.loginForm.value.username)){
      this.errorMessage="Enter valid email";
     
    }else {
      // making api call
      var data = {

        email: this.loginForm.value.username,
        password: this.loginForm.value.password
      };
      this.loginService.login(data).subscribe((result: any) => {
        this.loginForm.setValue({
          username: '',
          password: ''
        });
        // saving the result in cookie service
        this.cookieService.set("isLoggedIn", "true");//, undefined, undefined, undefined, true, 'None');
        this.cookieService.set("fullName", result.result.user.full_name);//, undefined, undefined, undefined, true, 'None');
        this.cookieService.set("role", result.result.user.role);//, undefined, undefinedq, undefined, true, 'None');
        this.router.navigate(['/home']);
      }, (err) => {
        this.errorMessage = err.error.message;
      })
    }
  }
}
