import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import {InviteComponent} from './invite/invite.component';
import { RouteGuard } from './middleware/route.guard';
import {HeaderComponent} from './header/header.component'
import {DownloadBaselinesComponent} from './download-baselines/download-baselines.component';
import { UserComponent } from './users/users.component';


const routes: Routes = [
  {
    path :'',
    // component :AppComponent
    component:LoginComponent
},
{
  path: 'login',
  component: LoginComponent
},
// {
//   path: 'home',
//   canActivate: [RouteGuard],
//   component: HomePageComponent
// },
{
  path: '',
  canActivate: [RouteGuard],
  component: HeaderComponent,
  children: [
    {
      path: 'home',
      component: HomePageComponent,
      data:{}
    },
  {
    path: 'invite',
  // canActivate: [RouteGuard],
  component: InviteComponent,
  data: {
    id : '#invite'
  }
  },{
    path: 'users',
  // canActivate: [RouteGuard],
  component: UserComponent,
  data: {
    id : '#users'
  }
  },
  {
    path: 'download-baseLines',
  // canActivate: [RouteGuard],
  component: DownloadBaselinesComponent,
  data: {
    id : '#download-baseLines'
  }
  }]
  // component: HomePageComponent
},
// {
//   path: 'invite',
//   canActivate: [RouteGuard],
//   component: InviteComponent
// }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  // constructor(private _router: Router,
  //   private _activatedRoute: ActivatedRoute,
  //   private cookieService: CookieService, ) {
  //   this._router.events.subscribe(event => {
  //     // Navigation end called after the route change
  //     if (event instanceof NavigationEnd) {
  //       var isLoggedIn, userRole;
  //       if (this.cookieService.get('role')) {
  //         userRole = this.cookieService.get('role');
  //       }
  //       if (this.cookieService.get('isLoggedIn')) {
  //         isLoggedIn = this.cookieService.get('isLoggedIn');
  //       }
  //       // Navigate to login page when user is not logged in or no data persent in the router object
  //       if (this._activatedRoute.snapshot.firstChild.firstChild && this._activatedRoute.snapshot.firstChild.firstChild.data.path !== 'login' && (!isLoggedIn || !userRole)) {
  //         this._router.navigate(['/login']);
  //       }

  //     }
  //   });

  // }
  constructor(){}
}
