import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class HomePageService {
  constructor( private http : HttpClient ) { }
  // private s2ab (s): any {
  //   const buf = new ArrayBuffer(s.length);
  //   const view = new Uint8Array(buf);
  //   for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  //   return buf;
  // }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    //  const data: Blob = new Blob([this.s2ab(buffer)], {
    //   type: EXCEL_TYPE
    // });
     FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

  downloadBaselines(groupIds) {
    const options: any =  {responseType : 'blob', observe: 'response'};
    if (!groupIds) {
      return this.http.get(environment.serverUrl + '/api/v2/baselines/download', options);
    } else {
      return this.http.get(environment.serverUrl + '/api/v2/download/baselines?group=' + groupIds, options);
    }
  }
}
