import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor( private http : HttpClient ) { }
  login(data){
    var headers = new Headers();
    // headers.append("x-header-authtoken","");
    // headers.append("x-app-version","1.0.0");
    // headers.append("refreshtoken","");
    // headers.append("os-type","ios");
    // headers.append( "Content-Type","application/json");
    // headers.append( "role","admin");
    return this.http.post(environment.serverUrl + '/api/v2/loginAdmin',data)
  }
}
