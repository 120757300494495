import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from './login/login.service';
import { ReactiveFormsModule ,FormsModule} from '@angular/forms';
// import {MatFormFieldModule} from '@angular/material/form-field';
import {MatFormFieldModule} from  '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomePageComponent } from './home-page/home-page.component';
import { AuthService } from './authentication/auth.service';
import { HttpInterceptorService } from './authentication/http-interceptor.service';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import { InviteComponent } from './invite/invite.component';
import { FileSaverModule } from 'ngx-filesaver';
import { HeaderComponent } from './header/header.component';
import { DownloadBaselinesComponent } from './download-baselines/download-baselines.component';
import { MatSelectModule } from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule, MatTableModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material';
import { UserComponent } from './users/users.component';
import { InviteService } from './invite/invite.service';
import { UserService } from './users/users.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomePageComponent,
    InviteComponent,
    HeaderComponent,
    DownloadBaselinesComponent,
    UserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatInputModule,
    MatChipsModule,
    MatSelectModule,
    MatIconModule,
    MatNativeDateModule,
    MatRippleModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
    FileSaverModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    NgxDatatableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatRadioModule,
    MatTableModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    CookieService,
    LoginService,
    AuthService,
    InviteService,
    UserService,
    InviteService,
    HttpInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'accent' },
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
