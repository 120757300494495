import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth.service';
// import { LoaderService } from '../loader/loader.service';


@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  private httpCount = 0;

  constructor(private authService: AuthService,
    // private loaderService: LoaderService
  ) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    // let hideLoader = request.headers.get('hideLoader') == 'true' ? true : false;

    // if (!hideLoader) {
    //   this.httpCount += 1;
    //   this.loaderService.changeLoadingVisibility(true);
    // }

    // request = request.clone({
    //   headers: request.headers
    //     .set('x-header-authToken', this.authService.getAuthToken())
    // });
    request = request.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "x-header-authtoken": this.authService.getAuthToken(),
        "x-app-version": "1.0.0",
        "refreshtoken":this.authService.getRefreshToken(),
        "os-type": "ios",
        "role": "admin",
        "userId":"5e61d985ddf6725b35eec148"
      })
    });

    return next.handle(request).pipe(
      map((res: HttpResponse<any>) => {
        if (res && res['type'] !== 0) {
          if (res.body && res.body.tokens) {
            this.authService.setAuthToken(res.body.tokens.authToken);
            this.authService.setRefreshToken(res.body.tokens.refreshToken);
          }
          if (this.httpCount > 0) {
            this.httpCount -= 1;
          }
          // if (this.httpCount === 0) {
          //   this.loaderService.changeLoadingVisibility(false);
          // }
          return res;
        }
      }),
      catchError((error: any) => {
        console.log(error);
        if (this.httpCount > 0) {

          this.httpCount -= 1;

        }
        // if (this.httpCount === 0) {
        //   this.loaderService.changeLoadingVisibility(false);
        // }
        if (error.status === 401 || error.name === 'UnauthorizedError' || (error.error && error.error.message === 'invalid token')) {
          this.authService.clearAllCookies();
        }
        //  else {
        //   this.loaderService.changeLoadingVisibility(false);
        // }
        return throwError(error);
      })
    );
  }

}
