import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private cookieService: CookieService) { }

  public setAuthToken(token): void {
    this.cookieService.set('x-header-authToken', token);//, undefined, '/', undefined, true, 'None');
  }

  public getAuthToken(): string {
    return this.cookieService.get('x-header-authToken');
  }

  public setRefreshToken(token): void {
    this.cookieService.set('refreshToken', token);//, undefined, '/', undefined, true, 'None');
  }

  public getRefreshToken(): string {
    return this.cookieService.get('refreshToken');
  }

  public clearAllCookies() {
    this.cookieService.deleteAll();
  }

}
