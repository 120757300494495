import { Component, OnInit } from '@angular/core';
import { HomePageService } from './home-page.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { FileSaverService } from 'ngx-filesaver';
import * as _ from 'lodash';



@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {

  constructor(
    private HomePageService: HomePageService,
    private _router: Router,
    private cookieService: CookieService,
    private _FileSaverService: FileSaverService,
  ) { }

  ngOnInit() {

  }
  invite() {
    this._router.navigateByUrl('invite');
  }
  downloadBaseLines() {
    this._router.navigateByUrl('download-baseLines');
    /* this.HomePageService.downloadBaselines().subscribe((result: any) => {
      if(result.result.length>0){
        _.forEach(result.result, function (value) {
          _.forEach(value, function (val,key1) {
            var type=typeof val;
            if(val && type==="object"){
                _.forEach(val, function (val2,key2) {
                  value[key1+' '+key2]=val2;
                });
              }
          });
        })
      //console.log(result.result);
        // _.forEach(result.result, function (value,key) {
        //     // value['username'] = (value.user && value.user['full_name']) ? value.user['full_name'] : "-";
        //     // console.log(value.user['full_name']);
        // });
    }
      // console.log(result.result);
     this.HomePageService.exportAsExcelFile(result.result, 'sample');
    }, (error) => {
      console.log(error);
      // Alert Service message comes here
    }) */

  }

  visible = true;




}
