export const storageConstants = {
	EQUIPMENTS: "equipments",
	OPERATIONS: "operations",
	OPSTYPE: "opsType",
	FORMPARAM: "formParam",
	ALERTS: "alerts",
	ALERTS_DATA: "alertMessages",
	ALERTS_DISPLAY_LIMIT: 5,
	ACTIVITIES: {
		COUNT_BASED: ["MUCKING", "DRILLING", "BOLTING", "REMUCK", "BACKFILL"
			, 'BOLTING - LONG SUPPORT', 'BOLTING - REHAB', 'LONG HOLE DRILLING', 'POWDER LOADING',
			'SHOTCRETING', 'HAULING'],
		DURATION_BASED: ["CLEAN FOR DRILLING", 'CUT TOE', 'PREP TO BACKFILL', 'PREP TO JAM', 'SUPPLY HEADING'
			, "TRAMMING",]
	},
	LINK_DISABLE_TEXT: {
		NO_PERMISSION: "You don't have privilage to perform this action !"
	},
	DASHBOARD_AUTO_REFRESH_TIME: 300,  //In seconds
	MAX_SAFETY_MESSAGES_LIMIT: 5,
	ACTIVE_REASON: "OPERATING",
	PLANNED_DOWN_REASON: "PLANNED MAINTANANCE",
	TASK_BASED_STATES: ["PLANNED DOWN"],
	VERSION_NUMBER: 'v6.0.1.08702016',
} 