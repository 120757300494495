import { Component, OnInit, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UserService } from './users.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';


export interface Groups {
  _id: string;
  group_code: string;
}

export interface Invites {
  name: string;
}
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UserComponent implements OnInit {

  @ViewChild('chipList', { static: false }) chipList;

  selectable = true;
  removable = true;
  addOnBlur = true;
  // emails: FormGroup;
  errorMessage: string;
  emailError: string;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  inviteEmails: Invites[] = [

  ];
  selectedItem: string;
  groups: Groups[] = [];
  filteredGroupOptions: Observable<Groups[]>;
  dataArray = this.groups;
  showCreateGroup = false;
  invitationListRows = [];
  userRole = "";
  usersList: MatTableDataSource<any>;
  selectedGroups = {};
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  usersColumns = ['name', 'email', 'group', 'role', 'gender'];
  constructor(
    private _userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar) { }


  ngOnInit() {
    this._userService.getAllGroups().toPromise().then((res: any) => {
      this.groups = res.result;
    }).catch((err: any) => {
      console.log(err);
    });
    this._userService.getAllUsers().toPromise().then((res: any) => {

      this.usersList = new MatTableDataSource(res.result);
      res.result.forEach((user) => {
        this.selectedGroups[user._id] = this.parseGroupId(user);
      });
      this.usersList.filterPredicate = (data, filter) => {
        const dataStr = data.full_name + data.role + data.email + data.gender + (data.group_code ? data.group_code.group_code : "");
        if (!dataStr.length) {
          return false;
        }
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.usersList.sort = this.sort;
    }).catch((err: any) => {
      console.log(err);
    });
  }

  gotoHome() {
    this.router.navigate(['/home']);
  }

  applyFilter(filterValue: string) {
    this.usersList.filter = filterValue.trim().toLowerCase();
  }

  updateGroup(event, user) {
    console.log(event);
    let groups = event.value;
    if (!groups.length) {
      this.snackBar.open('Must have atleast one group', 'OK', {
        duration: 5000,
        verticalPosition: 'top'
      });
      return;
    }
    //let user = user;
    user.group_code = groups;
    
    this._userService.updateUserGroup(user).toPromise().then((res: any) => {
      user.editGroup = false;
      user.group_code = _.map(
        _.filter(this.groups, (g) => {
          return _.includes(groups, g._id);
        }), (res) => _.pick(res, 'group_code', '_id'));
      this.snackBar.open('Updated User group successfully', 'OK', {
        duration: 5000,
        verticalPosition: 'top'
      });
    }).catch((err: any) => {
      console.log(err);
    });
  }

  parseGroupNames(user) {
    if (!user.group_code) {
      return '';
    }
    return user.group_code.map(u => u.group_code).join(', ');
  }

  parseGroupId(user) {
    if (!user.group_code) {
      return [];
    }
    let val = user.group_code.map(u => u['_id']);
    //console.log(val);
    return val;
  }

}
