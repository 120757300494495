import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentPage: any;
   highLight:any;
   toogleCollapse;
  isLoggedIn;
  constructor(
    private cookieService: CookieService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this._activatedRoute.snapshot.firstChild)
          this.currentPage = this._activatedRoute.snapshot.firstChild.routeConfig.path;
          if(this._activatedRoute.snapshot.firstChild.data && this._activatedRoute.snapshot.firstChild.data.id) this.toogleCollapse = this._activatedRoute.snapshot.firstChild.data.id;this.highLight = this._activatedRoute.snapshot.queryParams._from;
      }
    });
  }


  ngOnInit() {
    this.isLoggedIn = this.cookieService.get('isLoggedIn');
  }
  invite() {
    this._router.navigateByUrl('invite');
  };

  downloadBaseLines() {
    this._router.navigateByUrl('download-baseLines');
  }
  logout() {
    this.cookieService.deleteAll();
    localStorage.clear();
    this._router.navigateByUrl('/login');
  }
  gotoHome() {
    this._router.navigate(['/home']);
  }
}
