import { Component } from '@angular/core';
// import { LoaderService } from './loader/loader.service';
import { Router } from '@angular/router';
// import { AnimationItem } from 'lottie-web';
// import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'public';
  isLoading: boolean = false;
  currentPage : any;
  // options: AnimationOptions = {
  //   path: '/assets/groundHog-loader.json'
  // };

  // constructor(private loaderService : LoaderService, private router: Router){

  //   this.loaderService.loadingChange.subscribe(value => {
  //     this.isLoading = value;
  //     this.currentPage = this.router.url;
  //     // console.log("App Component Loading is ", this.isLoading);
  //   });
  // }
}
