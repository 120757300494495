import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  constructor( private http : HttpClient ) { }
  inviteUsers(data){
    return this.http.post(environment.serverUrl + '/api/v2/inviteUsers', data);
  }
  getAllGroups() {
    return this.http.get(environment.serverUrl + '/api/v2/groups');
  }

  getAllInvites() {
    return this.http.get(environment.serverUrl + '/api/v2/invites');
  }
}
