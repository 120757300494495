import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard  {

  constructor(private router : Router, private cookieService : CookieService){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
      const isLoggedIn = this.cookieService.get('isLoggedIn');
      if(isLoggedIn === "true"){
        return true
      }
      this.router.navigateByUrl('/login');
      return false;
  }

}
